import { styled } from '@mui/material/styles';
import Tab from "@mui/material/Tab";

export default styled(Tab)(() => ({
  fontWeight: "bold",
  fontSize: "0.8rem",
  borderRadius: "50px",
  width: "50%",
  transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
  "&.focused": {
    backgroundColor: "#fff",
  },
}));
