import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";

function SlideComponent(props) {
  return <Slide {...props} direction="left" />;
}

export default (props) => {
  const { onClick, children } = props;
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsShown(true), 5000);
  }, []);

  const SnackbarContent = React.forwardRef((_, ref) => (
    <div
      ref={ref}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 16px",
      }}
    >
      {children}
    </div>
  ));

  return (
    <Snackbar
      open={isShown}
      onClick={onClick}
      TransitionComponent={SlideComponent}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transitionDuration={{ enter: 1400 }}
      sx={{ bottom: { xs: "24px", md: "8px" } }}
    >
      <SnackbarContent />
    </Snackbar>
  );
}
