import React from "react";
import { Stack, Alert, Card, CardContent, CardHeader, CardActions, Link, AlertTitle, Typography } from "@mui/material";
import { CheckboxElement, TextFieldElement, RadioButtonGroup } from "react-hook-form-mui";
import SendIcon from "@mui/icons-material/Send";

import SubmitButton from "../elements/SubmitButton";

export default (props) => {
  const { isUnderSubmission, submittedEmail } = props;

  return (
    <Card variant="outlined" sx={{ border: 0 }}>
      <CardHeader
        title="メルマガ登録はこちらから！"
        subheader="探究学習に関する最新情報や、学校の取り組み事例などを発信しています。"
        titleTypographyProps={{ variant: "h2", m: 0, mb: 1, color: "primary" }}
        subheaderTypographyProps={{ variant: "body1", lineHeight: 1.6 }}
      />
      {!!submittedEmail ? (
        <CardContent>
          <Alert severity="success" color="primary">
            <AlertTitle sx={{ fontSize: "1rem", lineHeight: 1.6 }}>登録が完了しました。</AlertTitle>
            {submittedEmail} にメールを送信しました。届かない場合は迷惑メールフォルダをご確認ください。
          </Alert>
        </CardContent>
      ) : (
        <>
          <CardContent>
            <Stack spacing={3}>
              <TextFieldElement
                name="email"
                label="メールアドレス"
                required
                type="email"
              />
              <TextFieldElement
                name="organization"
                label="学校名／所属名"
              />
              <RadioButtonGroup
                name="type"
                label="種別"
                required
                row
                valueKey="label"
                options={[
                  { label: "高校" },
                  { label: "中学校" },
                  { label: "小学校" },
                  { label: "その他" },
                ]}
              />
              <CheckboxElement
                name="isAgreed"
                label={
                  <Typography variant="body2">
                    <Link href="/policy" target="_blank" underline="always">プライバシーポリシー</Link>に同意します。
                  </Typography>
                }
                required
              />
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <SubmitButton
              label="登録する"
              isLoading={isUnderSubmission}
              endIcon={<SendIcon />}
              loadingPosition="end"
            />
          </CardActions>
        </>
      )}
    </Card>
  );
}
