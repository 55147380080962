import Top from "./Top";
import Policy from "./Policy";
import Manual from "./Manual";

export default [
  {
    path: "/",
    Component: Top,
  },
  {
    path: "/policy",
    Component: Policy,
  },
  {
    path: "/manual",
    Component: Manual,
  },
];