import React from 'react';
import Button from '@mui/material/Button';

export default (props) => {
  const { href } = props;
  return (
    <Button
      color="primary"
      variant="outlined"
      href={href}
      target="_blank"
    >
      View More
    </Button>
  );
}
