import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormContainer } from "react-hook-form-mui";

export default (props) => {
  const { isOpen, handleClose, handleSubmit, children } = props;

  return (
    <Dialog open={isOpen} onClose={handleClose} PaperProps={{ sx: { p: 3 } }}>
      <FormContainer onSuccess={handleSubmit}>
        {children}
      </FormContainer>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}
