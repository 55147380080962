
import React from "react";
import { Card, CardActionArea, CardContent, CardMedia, Grid } from "@mui/material";

import Section from "../components/Section";

export default () => {
  return (
    <Section
      id="sponsor"
      h1="Sponsor"
      caption="協賛パートナー"
    >
      <Grid container spacing={4} justifyContent="center">
        {sponsorList.map((sponsor) => (
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea
                href={sponsor.url}
                target="blank"
              >
                <CardContent>
                  <CardMedia
                    component="img"
                    image={sponsor.imgUrl}
                    alt={sponsor.name}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
}

const sponsorList = [
  {
    name: "H.M.マーケティングリサーチ",
    url: "https://www.hmmr.co.jp",
    imgUrl: "https://www.hmmr.co.jp/assets/images/common/logo.svg",
  },
  {
    name: "SAKURUG",
    url: "https://sakurug.co.jp",
    imgUrl: "https://sakurug.co.jp/wp-content/themes/sakurug/assets/img/sakurug-logo.svg",
  },
];
