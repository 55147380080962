import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Circle from "../elements/Circle";
import Br from "../elements/Br";
import DelaySnackbar from "../elements/DelaySnackbar";
import CtaBtn from "../elements/CtaBtn";
import NavItem from "../elements/NavItem";

import MagazineFormCard from "../components/MagazineFormCard";

import problemManual from "../utils/problemManual.json";
import scienceManual from "../utils/scienceManual.json";

import bg1 from "../assets/manual-bg-1.png";
import bg2 from "../assets/manual-bg-2.png";
import bg3 from "../assets/manual-bg-3.png";
import bg4 from "../assets/manual-bg-4.png";
import FormDialog from "../elements/FormDialog";

export default () => {
  const theme = useTheme();
  const isMediaWide = useMediaQuery(theme.breakpoints.up("md"));

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [isUnderSubmission, setIsUnderSubmission] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(null);

  function handleSubmit(data) {
    setIsUnderSubmission(true);
    fetch("https://script.google.com/macros/s/AKfycby2F2GR0wbg94jLYa8vk9D7WordIrkQ6c64Tm-75zzBbUiI9N8bpUykwo-Y_ID2Kp_4/exec", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => {
        setIsUnderSubmission(false);
        if (res.ok) { setSubmittedEmail(data.email); }
      })
      .catch(console.error);
  }

  function handleDialogOpen() {
    setIsDialogOpen(true);
  }

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <Container maxWidth="md" sx={{ padding: { xs: 0, sm: "inherit" } }}>
        <Card elevation={4} sx={{ m: { xs: 1, md: 2 }, p: { xs: 0.5, sm: 2 } }}>
          <CardContent>
            {/* <Typography variant="h1" textAlign="center">
              当サイトについて
            </Typography> */}
            <Typography variant="h2" textAlign="center">
              <span>探究学習に関わる<Br/>先生方へ</span><Br media="md"/>
            </Typography>
            <Typography variant="body1" textAlign="center" sx={{ lineHeight: 2 }}>
              このページでは、中高探究学習における生徒への指導に関する実践知をまとめています。<br />
              「10代の『やってみたい』を育てる、叶える。」をミッションに掲げ、{isMediaWide && <br/>}探究学習を支援する一般社団法人e-donutsが運営しています。
            </Typography>
            {/* <Stack>
              <CtaBtn
                href="https://www.e-donuts.com/"
                sx={{ margin: "16px auto" }}
              >
                公式サイトへ
              </CtaBtn>
            </Stack> */}
            <Grid container rowSpacing={2} columnSpacing={3} my={4} justifyContent="center">
              {[
                {
                  type: "classroom",
                  body: <>日々の探究学習の中で<br/>生徒に行うべき<span>ファシリテーション</span><br/>に関する実践知を蓄積</>,
                },
                {
                  type: "framework",
                  body: <>要点を抽出することで<br/>「忘れない」「すぐ実践できる」<br/><span>フレームワーク</span>を作成</>,
                },
                {
                  type: "book",
                  body: <>生徒との具体的なやりとり<br/>の様子を示した<br/><span>ケーススタディ</span>を掲載</>,
                }
              ].map(({ type, body }, i) => (
                <Grid key={i} item xs={12} sm={6} md={4}>
                  <Stack spacing={2} alignItems="center">
                    <Circle type={type} flexDirection="row" size="148px" imgSize="44px" />
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.85rem",
                        lineHeight: "1.4rem",
                        textAlign: "center",
                        "&>span": {
                          color: "primary.main",
                        },
                      }}
                    >
                      {body}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Typography color="#aaa" fontSize={10} lineHeight={1.8} mx={4}>
              ※当マニュアルは、正確な学問研究としての知見ではなく「高校の探究学習で実践的に活用可能な知見」という文脈で体系化されたものであることをご了承ください。<br />
              ※当マニュアルは、教育関係者の皆様と共にアップデートしていく「オープンソース的な活用」として捉えており、皆様の意見を基にさらに昇華させていくことを想定しています。<br />
            </Typography>
          </CardContent>
        </Card>
        <Card elevation={4} sx={{ margin: "16px" }}>
          <CardContent sx={{ padding: { xs: "24px !important", sm: "30px !important", md: "42px" } }}>
            <Stack spacing={2} mx="5%" px={6} py={4} sx={{ bgcolor: "#eee" }}>
              <Typography variant="h3">目次</Typography>
              {anchorList.map((item, i) => (
                <NavItem
                  key={i}
                  {...item}
                  sx={{
                    textAlign: "left",
                    color: "primary.main",
                    borderBottom: "1px dashed",
                    width: "fit-content",
                  }}
                />
                            // <Typography key={i} variant="body2" sx={{borderBottom: "1px dashed black"}}>
                //   <a href={href} style={{ color: "#368ed2" }}>
                //     {title}
                //   </a>
                // </Typography>
              ))}
            </Stack>
          </CardContent>
        </Card>

        <Card id="problemRecommend" elevation={4} sx={{ margin: "16px" }}>
          <CardContent sx={{ padding: { xs: "24px !important", sm: "30px !important", md: "42px" } }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ width: "16px", height: "16px", bgcolor: "primary.main" }} />
              <Typography variant="h2">
                課題解決型探究のススメ
              </Typography>
            </Stack>
            {problemManual.map((data, i) => (
              <ManualCardBlock key={i} {...data} />
            ))}
          </CardContent>
        </Card>

        <Card id="scienceRecommend" elevation={4} sx={{ margin: "16px" }}>
          <CardContent sx={{ padding: { xs: "24px !important", sm: "30px !important", md: "42px" } }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ width: "16px", height: "16px", bgcolor: "primary.main" }} />
              <Typography variant="h2">
                科学研究型探究のススメ
              </Typography>
            </Stack>
            {scienceManual.map((data, i) => (
              <ManualCardBlock key={i} {...data} />
            ))}
          </CardContent>
        </Card>
      </Container>

      <DelaySnackbar onClick={handleDialogOpen}>
        <CtaBtn
          onClick={handleDialogOpen}
          EndIcon={null}
          StartIcon={InfoOutlinedIcon}
          color="success"
          sx={{
            px: 3,
            width: "fit-content",
          }}
        >
          <Typography variant="body2" fontWeight="bold" color="inherit" sx={{ pl: 1 }}>
            探究学習に関する<br/>最新情報を受け取る
          </Typography>
        </CtaBtn>
      </DelaySnackbar>

      <FormDialog
        isOpen={isDialogOpen}
        handleClose={handleDialogClose}
        handleSubmit={handleSubmit}
      >
        <MagazineFormCard {...{ isUnderSubmission, submittedEmail }} />
      </FormDialog>

      {isMediaWide && [bg1, bg2, bg3, bg4].map((src, i) => (
        <div
          key={i}
          style={{
            position: "absolute",
            top: `${i*25 + 5}%`,
            right: (i%2) ? undefined : "32px",
            left: (i%2) ? "32px" : undefined,
            zIndex: -1,
          }}
        >
          <img
            src={src}
            alt=""
            loading="lazy"
          />
        </div>
      ))}

    </div>
  );
}

function ManualCardBlock(props) {
  const { id, type, title, label, list = [],  } = props;

  function renderContent() {
    switch (type) {
      case "assumption":
        return (
          <Stack spacing={2}>
            {list.map((item, i) => (
              <Box key={i} variant="body2" >
                {item.split("[link]").map((text, j) => !!(j % 2)
                  ? <NavItem key={j} label={text.split("$$")[2]} id={text.split("$$")[1]} sx={{ display: "inline", textDecoration: "underline" }} />
                  : <Typography key={j} variant="body2" sx={{ display: "inline" }}>{text}</Typography>
                )}
              </Box>
            ))}
          </Stack>
        );
      case "definition":
        return (
          <Stack spacing={2}>
            {list.map((item, i) => (
              <Grid container key={i} rowSpacing={2}>
                <Grid item xs={4} md={2}>
                  <Typography
                    color="primary.main"
                    fontWeight="bold"
                    textAlign="center"
                    sx={{
                      width: "80%",
                      minWidth: "80px",
                      bgcolor: "#eee",
                    }}
                  >
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Stack spacing={0.5}>
                    <Typography fontWeight="bold" lineHeight={1.7}>{item.title}</Typography>
                    <Typography color="#aaa" lineHeight={1.7}>{item.body}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            ))}
          </Stack>
        );
      case "framework":
        return (
          <Stack spacing={2}>
            {list.map(({ title: title2, list: list2 = [] }, i) => (
              <Accordion
                key={i}
                sx={{ boxShadow: "none" }}
                disableGutters
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "#eee",
                    "&.Mui-expanded": {
                      backgroundColor: "primary.main",
                    },
                    "&.Mui-expanded *": {
                      color: "#fff !important",
                    },
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    lineHeight={2}
                    sx={{
                      "&:not(.Mui-expanded *)::first-letter": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {title2}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ px: { xs: 0.5, md: 2 } }}>
                  <Stack spacing={1}>
                    {list2.map(({ title: title3, list: list3 = [] }, j) => (
                      <Accordion
                        key={j}
                        sx={{ boxShadow: "none", borderRadius: "8px !important", border: "1px solid", borderColor: "primary.main" }}
                        disableGutters
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="primary" />}
                          sx={{
                            backgroundColor: "#e5eeff",
                          }}
                        >
                          <Typography color="primary.main" fontWeight="bold" lineHeight={2}>{title3}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            borderTop: "1px solid",
                            borderTopColor: "primary.main",
                          }}
                        >
                          <Stack spacing={2}>
                            {list3.map(({ body: body4, list: list4 = [] }, k) => (
                              <Stack key={k} spacing={0.8}>
                                <Typography fontWeight="bold" lineHeight={2}>{body4}</Typography>
                                <Stack spacing={0.5} ml="12px !important">
                                  {list4.map((item, l) => (
                                    <Typography key={l} lineHeight={1.7} sx={{ whiteSpace: "pre-line" }}>{item}</Typography>
                                  ))}
                                </Stack>
                              </Stack>
                            ))}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        );
      case "caseStudy":
        return (
          <>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>{label}</Typography>
            <Grid container spacing={2} mt={2}>
              {list.map((item, i) => (
                <Grid key={i} item xs={12} md={6}>
                  <CtaBtn
                    href={item.link}
                    sx={{
                      width: "100%",
                      pl: 2,
                      pr: 4,
                    }}
                  >
                    {item.title}<Br/>「{item.label}」
                  </CtaBtn>
                </Grid>
              ))}
            </Grid>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <Stack id={id}>
      <Typography
        variant="h3"
        color="primary.main"
        fontSize={16}
        sx={{
          borderBottom: "1px solid",
          borderBottomColor: "primary.main",
          py: 1,
          my: 4,
        }}
      >
        {title}
      </Typography>
      {renderContent()}
    </Stack>
  )
}

const anchorList = [
  {
    label: "■ 課題解決型探究のススメ",
    id: "problemRecommend",
  },
  {
    label: "■ 科学研究型探究のススメ",
    id: "scienceRecommend",
  }
  // 一旦実装ペンディング
  // {
  //   label: "■ 先生たちに聞いてみました",
  //   id: "teacherInterview",
  // }
];
