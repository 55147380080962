import React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
// import Grid from "@mui/material/Grid";

import Section from "../components/Section";
import MemberCard from "../components/MemberCard";

import katayama from "../assets/advisor-katayama.jpeg";

export default () => {
  return (
    <Section
      id="advisor"
      caption="外部助言・監修"
      h1="Advisor"
    >
      <Container maxWidth="lg" sx={{ padding: { xs: 0, sm: "inherit" } }}>
        <Stack spacing={3} useFlexGap>
          <MemberCard
            isRepresentative
            imgSrc={katayama}
            name="片山 俊大"
            romajiName="Toshihiro Katayama"
            role="Advisor"
            historyList={[
              { text: "一般社団法人スペースポートジャパン共同創業者・理事" },
            ]}
            description={
              <>
                広告会社入社後、幅広い領域のプロジェクトに従事し、2015年より日本政府・地方公共団体のパブリック戦略担当を歴任。<br/><br/>
                日本とUAEの宇宙・資源外交に深く携わったことをきっかけに、宇宙関連事業開発に従事。<br/><br/>
                2018年、日本にスペースポート（宇宙港）を実現するための業界団体「一般社団法人スペースポートジャパン」を共同創業し、スペースポートによる地方経済の活性化を行っている。<br/><br/>
                著書『超速でわかる！宇宙ビジネス』（すばる舎,2021）
              </>
            }
          />
          {/* <Grid container rowSpacing={3} columnSpacing={1}>
            <Grid item xs={12} md={6}>
              <MemberCard
                imgSrc={kazato}
                name="植松 風登"
                romajiName="Kazato Uematsu"
                role="理事"
                historyList={[
                  { year: 2018, text: "株式会社OUTSENSE 共同創業" },
                  { year: 2022, text: "京都大学工学部 自主退学" },
                  { year: 2022, text: "PROJECT GROUP株式会社 入社" },
                ]}
              />
            </Grid>
          </Grid> */}
        </Stack>
      </Container>
    </Section>
  );
}
