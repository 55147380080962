import React from "react";
import clsx from "clsx";
import { HashLink } from "react-router-hash-link";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default (props) => {
  const { id, label, focusedSectionId, onClick, sx } = props;

  function scroll(el) {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  }

  return (
    <Box
      key={id}
      component={HashLink}
      elementId={id}
      scroll={scroll}
      smooth
      onClick={onClick ? () => onClick(id) : undefined}
      className={clsx({ focused: id === focusedSectionId })}
      sx={{
        position: "relative",
        fontSize: "0.7rem",
        fontWeight: "bold",
        textDecoration: "none",
        color: "inherit",
        transition: "color 0.3s ease-in-out",
        "&.focused": {
          color: "primary.main",
        },
        "&::after": {
          content: "''",
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 0,
          height: "2px",
          backgroundColor: "primary.main",
          transition: "width 0.3s ease-in-out",
        },
        "&.focused::after": {
          width: "100%",
        },
        ...sx,
      }}
    >
      <Typography variant="button">
        {label}
      </Typography>
    </Box>
  );
};

// export default styled(Box)(({ theme }) => ({
//   position: "relative",
//   fontSize: "0.8rem",
//   fontWeight: "bold",
//   textDecoration: "none",
//   color: "inherit",
//   transition: "color 0.3s ease-in-out",
//   "&.focused": {
//     color: theme.palette.primary.main,
//   },
//   "&::after": {
//     content: "''",
//     position: "absolute",
//     left: 0,
//     bottom: 0,
//     width: 0,
//     height: "2px",
//     backgroundColor: theme.palette.primary.main,
//     transition: "width 0.3s ease-in-out",
//   },
//   "&.focused::after": {
//     width: "100%",
//   },
// }));
