import React from "react";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Section from "../components/Section";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default () => {
  return (
    <Section
      isDark
      id="news"
      caption="最新情報"
      h1="News"
    >
      <Container maxWidth="lg" sx={{ padding: { xs: 0, sm: "inherit" } }}>
        <List sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", paddingY: 0 }}>
          {newsList.map(({ title, date, url }, i) => (
            <ListItem
              key={i}
              divider
              // secondaryAction={<ChevronRightIcon fontSize="12px" color="#666" />}
              component="a"
              href={url}
              target="_blank"
              sx={{ paddingY: 2, ":hover": { opacity: 0.7 } }}
            >
              <Stack direction="row" spacing={2} alignItems="center" width="100%">
                <Typography
                  variant="body2"
                  sx={{ fontSize: "0.5rem", color: "#666" }}
                >
                  {date}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "0.7rem", color: "#333", lineHeight: "1.1rem" }}
                >
                  {title}
                </Typography>
                <ChevronRightIcon fontSize="12px" color="darkGrey" sx={{ marginLeft: "auto !important" }} />
              </Stack>
            </ListItem>
          ))}
        </List>
      </Container>
    </Section>
  );
}

const newsList = [
  {
    title: "協賛スポンサー企業に株式会社SAKURUG様が加わりました",
    date: "2023/11/06",
    url: "https://presswalker.jp/press/27365",
  },
  {
    title: "テラエナジーでんき「ほっと資産」の寄付先団体として認定を受けました",
    date: "2023/10/27",
    url: "https://presswalker.jp/press/26666",
  },
  {
    title: "e-donutsの取り組みが朝日新聞の紙面版・デジタル版に掲載されました",
    date: "2023/10/24",
    url: "https://www.asahi.com/articles/ASRBR7G1TRBBPLZB00J.html",
  },
  {
    title: "グローバルの3DプリンターメーカーであるPrusa Research社と提携しました",
    date: "2023/10/17",
    url: "https://presswalker.jp/press/25640",
  },
  {
    title: "co-mediaに代表のインタビュー記事が掲載されました",
    date: "2023/10/12",
    url: "https://www.co-media.jp/article/24811",
  },
  {
    title: "CAMPFIRE社のソーシャルグッド特化型プラットフォーム「GoodMorning」にてクラウドファンディングを開始しました",
    date: "2023/09/22",
    url: "https://camp-fire.jp/projects/view/658658",
  },
  {
    title: "マクロミル・博報堂グループのH.M.マーケティングリサーチの協賛により中高探究学習におけるリサーチ活用支援を開始",
    date: "2023/08/18",
    url: "https://presswalker.jp/press/19929",
  },
  {
    title: "非営利スタートアップ助成プログラムSoil100の1期生に採択されました",
    date: "2023/07/10",
    url: "https://presswalker.jp/press/17053",
  },
]
