import React from 'react';
import { useMediaQuery, useTheme } from "@mui/material";

import introLeftTop from "../assets/intro-left-top.jpeg";
import introLeftMiddle from "../assets/intro-left-middle.jpeg";
import introLeftBottom from "../assets/intro-left-bottom.jpeg";
import introRightTop from "../assets/intro-right-top.jpeg";
import introRightMiddle from "../assets/intro-right-middle.jpeg";
import introRightBottom from "../assets/intro-right-bottom.jpeg";

export default (props) => {
  const theme = useTheme();
  const isUnderSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { type } = props;
  let top, bottom, left, right, size, src;

  switch (type) {
    case "leftTop":
      src = introLeftTop;
      top = 70;
      left = 80;
      size = 60;
      break;
    case "leftMiddle":
      src = introLeftMiddle;
      top = "45%";
      left = 30;
      size = 90;
      break;
    case "leftBottom":
      src = introLeftBottom;
      bottom = 40;
      left = 100;
      size = 35;
      break;
    case "rightTop":
      src = introRightTop;
      top = 60;
      right = 80;
      size = 35;
      break;
    case "rightMiddle":
      src = introRightMiddle;
      top = "35%";
      right = 30;
      size = 60;
      break;
    case "rightBottom":
      src = introRightBottom;
      bottom = 40;
      right = 50;
      size = 90;
      break;
    default:
  }

  return (
    <div
      style={{
        position: "absolute",
        top,
        bottom,
        left,
        right,
        width: `${size}px`,
        height: `${size}px`,
        margin: `${size * 0.21}px auto`,
        transform: "rotate(45deg)",
        overflow: "hidden",
        zIndex: -1,
        opacity: isUnderSm ? 0.3 : 0.85,
      }}
    >
      <img
        src={src}
        alt=""
        style={{
          width: `${size * 1.41}px`,
          height: `${size * 1.41}px`,
          margin: `-${size * 0.21}px 0 0 -${size * 0.21}px`,
          transform: "rotate(-45deg)",
        }}
      />
    </div>
  );
}
