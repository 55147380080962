import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import Container from "@mui/material/Container";

import fv from "../assets/fv.jpeg";
import fvText from "../assets/fv-text.png";

export default () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <Container
      as="section"
      id="fv"
      maxWidth="lg"
      sx={{
        position: "relative",
        aspectRatio: 16 / 9,
        background: `url(${fv}) no-repeat center center`,
        backgroundSize: "cover",
        marginBottom: { sm: 0, md: "2rem" },
        "&::before": {
          content: "'Scroll'",
          display: { xs: "none", md: "block" },
          writingMode: "vertical-rl",
          fontSize: "0.7rem",
          position: "absolute",
          height: "136px",
          color: "#fff",
          bottom: 0,
          left: "50%",
          borderLeft: "1px solid #fff",
          zIndex: 2,
        },
        "&::after": {
          content: "''",
          display: { xs: "none", md: "block" },
          position: "absolute",
          height: "54px",
          width: "1px",
          top: "100%",
          left: "50%",
          backgroundColor: "#000",
        },
      }}
    >
      <animated.div
        style={{
          width: "40%",
          position: "absolute",
          zIndex: 1,
          top: "50%",
          left: "50%",
          ...useSpring({
            opacity: isImageLoaded ? 1 : 0,
            from: { opacity: 0, transform: "translate(-50%, -50%) scale(0.5)" },
            to: { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
            config: { duration: 500 },
          }),
        }}
      >
        <img
          src={fvText}
          alt="10代の「やってみたい」を育てる、叶える"
          style={{ width: "100%" }}
          onLoad={() => setIsImageLoaded(true)}
        />
      </animated.div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        }}
      />
    </Container>
  );
}