import React from "react";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

import CtaBtn from "./CtaBtn";

import googleFormUrl from "../utils/googleFormUrl.json";

export default () => {
  return (
    <div className="menu">
      <Paper>
        <MenuList sx={{ padding: 0 }}>
          {["school", "company"].map((type) => (
            <MenuContent key={type} type={type} />
          ))}
        </MenuList>
      </Paper>
    </div>
  );
}

function MenuContent(props) {
  const { type } = props;
  return (
    <MenuItem
      sx={{
        backgroundColor: (type === "school") ? "#fff" : "#000",
        "&:hover": {
          backgroundColor: (type === "school") ? "#fff" : "#000",
          opacity: 0.7,
        },
      }}
    >
      <CtaBtn
        href={googleFormUrl[type]}
        color={(type === "school") ? "secondary" : "white"}
        // color={(type === "school") ? "primary" : "primary2"}
        variant="text"
        size="small"
      >
        {(type === "school") ? "教育" : "企業"}関係者の方はこちら
      </CtaBtn>
    </MenuItem>
  );
}


// import React from "react";
// import { styled } from "@mui/material/styles";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

// import CtaBtn from "./CtaBtn";

// import googleFormUrl from "../utils/googleFormUrl.json";

// const StyledMenu = styled((props) => (
//   <Menu
//     elevation={0}
//     anchorOrigin={{
//       vertical: "bottom",
//       horizontal: "right",
//     }}
//     transformOrigin={{
//       vertical: "top",
//       horizontal: "right",
//     }}
//     disableScrollLock
//     dense
//     {...props}
//   />
// ))(() => ({
//   "& .MuiPaper-root": {
//     borderRadius: 6,
//     marginTop: 1,
//     backgroundColor: "rgba(0, 0, 0, 0.8)",
//   },
//   "& .MuiList-root": {
//     padding: "0 !important",
//   },
//   "& .MuiMenuItem-root": {
//     padding: "6px 8px !important",
//   },
// }));

// export default (props) => {
//   const { anchorEl, onClose } = props;
//   return (
//     <StyledMenu
//       anchorEl={anchorEl}
//       open={!!anchorEl}
//       onClose={onClose}
//     >
//       {["school", "company"].map((type) => (
//         <MenuContent key={type} type={type} />
//       ))}
//     </StyledMenu>
//   );
// }

// function MenuContent(props) {
//   const { type } = props;
//   return (
//     <MenuItem>
//       <CtaBtn
//         href={googleFormUrl[type]}
//         color="white"
//         variant="text"
//         size="small"
//       >
//         {(type === "school") ? "教育" : "企業"}関係者の方はこちら
//       </CtaBtn>
//     </MenuItem>
//   );
// }
