import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import routes from "./routes";
// import Top from "./routes/Top";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        {routes.map((props) => <Route {...props} key={props.path} />)}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
