import React, { useEffect, useState } from "react";
import { useFormState, useFormContext } from "react-hook-form-mui";
import LoadingButton from "@mui/lab/LoadingButton";

export default React.memo((props) => {
  const { label, defaultValue, isLoading = false, ...restProps } = props;
  const { isValid } = useFormState();
  // const { watch } = useFormContext();
  // const [isDirty, setIsDirty] = useState(false);

  // useEffect(() => {
  //   const subscription = watch((currentValue) => {
  //     setIsDirty(isDiff(currentValue, defaultValue));
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  return (
    <LoadingButton
      type="submit"
      variant="contained"
      color="primary"
      disabled={!isValid}
      loading={isLoading}
      sx={{
        width: "124px",
        pr: "36px",
      }}
      {...restProps}
    >
      {label}
    </LoadingButton>
  )
});
