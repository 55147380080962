import React from "react";
import Typography from "@mui/material/Typography";

import Section from "../components/Section";
import Br from "../elements/Br";

import DiamondImg from "../elements/DiamondImg";

export default () => {
  return (
    <Section
      id="introduction"
      caption="e-donutsについて"
      h1="Who We Are"
      h2={<>京都発、<span>公教育の探究学習を支援</span>する<Br/>ソーシャル企業</>}
      sx={{ position: "relative" }}
    >
      <Typography variant="body1">
        超スピードで社会が変動し、<Br/>正解のない問いに溢れるこの時代。<br/>
        今、公教育の現場においても、<br/>
        「一人ひとりが問いを立て、追求する探究的な学び」<Br/>が注目されています。<br/>
        より一層不確実なミライを生きる子どもたちが、<br/>
        「知りたいこと」「やってみたいこと」を育み、<Br/>叶える環境を作りたい。<br/>
        e-donutsは、現代の教育現場で奮闘する方々と共に、<br/>
        すべての探究的な学びの支援を行います。<br/>
      </Typography>
      <DiamondImg type="leftTop" />
      <DiamondImg type="leftMiddle" />
      <DiamondImg type="leftBottom" />
      <DiamondImg type="rightTop" />
      <DiamondImg type="rightMiddle" />
      <DiamondImg type="rightBottom" />
    </Section>
  );
}