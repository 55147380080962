import { styled } from '@mui/material/styles';
import Tabs from "@mui/material/Tabs";

export default styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-flexContainer": {
    display: "inline-flex",
    borderRadius: "50px",
    padding: "7px 9px",
    backgroundColor: theme.palette.grey.main,
    width: "94%",
    maxWidth: "480px",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));
