import React from 'react';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default (props) => {
  const { children, href, color = "primary", variant = "contained", EndIcon = ArrowForwardIcon, StartIcon, size, sx = {}, ...restProps } = props;
  return (
    <Button
      endIcon={!!EndIcon && <EndIcon fontSize="small" />}
      startIcon={!!StartIcon && <StartIcon fontSize="small" />}
      {...{ color, variant, size }}
      href={href}
      target="_blank"
      sx={{ minWidth: "180px", ...sx }}
      {...restProps}
    >
      {children}
    </Button>
  );
}
