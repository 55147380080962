import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";

export default (props) => {
  const { imgSrc, name, romajiName, role, historyList = [], cathcopy, description, isRepresentative } = props;
  const theme = useTheme();
  const isUnderMd = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = isUnderMd && !isUnderSm;

  function History() {
    return (
      <Stack
        spacing={1}
        px="0.8rem"
        py="0.2rem"
        pr={0}
        sx={{
          marginTop: "16px !important",
          borderLeft: "1px solid",
          borderLeftColor: "#333",
        }}
      >
        {historyList.map((history, i) => (
          <Typography
            key={i}
            variant="body2"
            sx={{
              textAlign: "left",
              fontSize: "0.7rem",
              letterSpacing: "0.02rem",
              whiteSpace: "nowrap",
              color: "#333",
              marginTop: "0 !important",
              "&>span": {
                fontSize: "0.6rem",
                color: "#666",
                marginRight: "0.5rem",
              },
            }}
          >
            <>{!!history.year && <span>{history.year}年</span>}{history.text}</>
          </Typography>
        ))}
      </Stack>
    );
  }

  return (
    <Card elevation={4}>
      <CardContent sx={{ padding: { xs: "24px !important", sm: "30px !important", md: `${isRepresentative ? "42px" : "24px 42px"} !important` } }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={isRepresentative ? 6 : 12}>
            <Stack direction="row" spacing={4}>
              <Avatar
                src={imgSrc}
                alt={name}
                sx={{
                  aspectRatio: 1,
                  height: "100%",
                  width: (isRepresentative && !isUnderMd) ? "50%" : "30%",
                }}
              />
              <Stack spacing={1} justifyContent="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.6rem",
                    color: "#666",
                  }}
                >
                  {romajiName}
                </Typography>
                <Typography variant="h4">{name}</Typography>
                <Typography variant="h5">{role}</Typography>
                {isMd && <History />}
              </Stack>
            </Stack>
            {!isMd && <History />}
          </Grid>
          {isRepresentative && (
            <Grid item xs={12} md={isRepresentative ? 6 : 12} sx={{ textAlign: "left" }}>
              <Typography variant="h2" sx={{ textAlign: "left", marginTop: 0, marginBottom: "20px" }}>{cathcopy}</Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.7rem",
                  lineHeight: "1rem",
                }}
              >
                {description}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
