import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import teacher from "../assets/school-teacher.png";

export default (props) => {
  const { children, type = "left" } = props;
  let direction = "row", borderColorKey = "borderRightColor";
  if (type === "right") {
    direction = "row-reverse";
    borderColorKey = "borderLeftColor";
  }

  return (
    <Stack {...{ direction }} alignItems="center" spacing={4}>
      <img src={teacher} alt="人" width="48px" />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          position: "relative",
          padding: "18px 24px",
          minWidth: "120px",
          backgroundColor: "grey.main",
          borderRadius: "8px",
          "&::before": {
            content: "''",
            position: "absolute",
            top: "50%",
            [type]: "-28px",
            marginTop: "-15px",
            border: "15px solid transparent",
            [borderColorKey]: "grey.main",
          },
        }}
      >
        <Typography variant="body2">
          {children}
        </Typography>
      </Box>
    </Stack>
  );
}
