import React from "react";

import FvSection from "../sections/FvSection";
import IntroductionSection from "../sections/IntroductionSection";
import ServiceSection from "../sections/ServiceSection";
import SponsorSection from "../sections/SponsorSection";
import ReportSection from "../sections/ReportSection";
import NewsSection from "../sections/NewsSection";
import TeamSection from "../sections/TeamSection";
import AdvisorSection from "../sections/AdvisorSection";
import AboutSection from "../sections/AboutSection";

export default () => {
  return (
    <div id="top">
      <FvSection />
      <IntroductionSection />
      <ServiceSection />
      <SponsorSection />
      <NewsSection />
      <ReportSection />
      <TeamSection />
      <AdvisorSection />
      <AboutSection />
    </div>
  );
}
