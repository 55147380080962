import React, { useState } from "react";
import { clsx } from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import "swiper/css";

import Section from "../components/Section";

import SwiperTabs from "../elements/SwiperTabs";
import SwiperTab from "../elements/SwiperTab";
import CtaBtn from "../elements/CtaBtn";
import Balloon from "../elements/Balloon";
import Circle from "../elements/Circle";
import Br from "../elements/Br";

import handshake from "../assets/handshake.png";
import team from "../assets/team.png";
import logo from "../assets/logo.png";
import hmm from "../assets/logo_hmm.svg";


import googleFormUrl from "../utils/googleFormUrl.json";

export default () => {
  const [swiper, setSwiper] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  function handleTabChange(_, i) {
    setTabIndex(i);
    swiper.slideTo(i);
  }

  return (
    <Section
      isDark
      id="service"
      caption="サービスについて"
      h1="Service"
      h2={<><span>「やってみたい」を後押し</span>する<Br/>産学連携プラットフォーム</>}
    >
      <Grid container spacing={1} alignSelf="center" p={2}>
        <Grid item xs={12} md={5} m="auto">
          <Typography
            variant="body1"
            sx={{ textAlign: { md: "center", lg: "left" } }}
          >
            自由な探究を尊重するほど、<br/>
            学校現場だけでは対応しきれない。<br/>
            外部連携ネットワークを構築し、<br/>
            <strong>学校・生徒主導の</strong>「社会に開かれた学び」を<br/>
            提供します。<br/>
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} m="auto">
          <Grid container pt={8} pb={12}>
            <Grid item xs={4}>
              <InstitutionStack
                type="school"
                label="学校"
                color="primary"
                position="left"
                text={
                  <>
                    探究を深化させるのに<br/>
                    校内だけでは<br/>
                    資源や知見が不足
                  </>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Stack alignItems="center" justifyContent="center" sx={{ position: "relative", zIndex: 1 }}>
                <img src={handshake} alt="" style={{ width: "72px", zIndex: 2 }} />
                <Stack
                  justifyContent="center"
                  sx={{
                    position: "absolute",
                    top: "95%",
                    width: "180px",
                    height: "90px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Stack
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      position: "relative",
                      "&::before": {
                        content: "''",
                        position: "absolute",
                        top: "calc(50% - 3px)",
                        left: "-4px",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "primary.main",
                      },
                      "&::after": {
                        content: "''",
                        position: "absolute",
                        top: "calc(50% - 3px)",
                        right: "-4px",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "secondary.main",
                      },
                    }}
                  >
                    <img src={team} alt="" width="32px" />
                    <img src={logo} alt="e-donuts" width="100px" />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <InstitutionStack
                type="company"
                label="企業"
                color="secondary"
                position="right"
                text={
                  <>
                    教育CSRを行いたい<br/>
                    次世代のファンを築きたい<br/>
                    ワカモノと共創をしたい
                  </>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="md" sx={{ backgroundColor: "#fff", padding: "32px 16px !important" }}>
        <SwiperTabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          textColor="primary"
        >
          {["教育", "企業"].map((label, i) => (
            <SwiperTab
              key={i}
              value={i}
              label={`${label}関係者の方へ`}
              className={clsx({ focused: i === tabIndex })}
            />
          ))}
        </SwiperTabs>
        <Box sx={{ margin: "auto", maxWidth: "540px" }}>
          <Swiper
            simulateTouch={false}
            slidesPerView={1}
            spaceBetween={30}
            onSlideChange={({ activeIndex }) => setTabIndex(activeIndex)}
            onSwiper={setSwiper}
          >
            <SwiperSlide>
              <SchoolSlide />
            </SwiperSlide>
            <SwiperSlide>
              <CompanySlide />
            </SwiperSlide>
          </Swiper>
        </Box>
      </Container>
      {/* <Typography variant="h2" >協賛パートナー</Typography>
      <a href="https://www.hmmr.co.jp/" target="blank">
        <Container maxWidth="lg" sx={{ padding: { xs: 0, sm: "inherit" } }}>
          <div style={popupStyle}>
              <div style={popupContentStyle}>
                <img src={hmm} alt="HMM" style={imageStyle} />
              </div>
          </div>
        </Container>
      </a> */}
    </Section>
  );
}

function InstitutionStack(props) {
  const { type, label, text, position, color } = props;
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "relative",
        height: "100%",
        "&::before": {
          content: "''",
          position: "absolute",
          top: "50%",
          [position]: "50%",
          width: "85%",
          height: "94px",
          borderTop: "2px dotted",
          borderBottom: "2px solid",
          borderLeft: (position === "left") ? "2px solid" : "none",
          borderRight: (position === "right") ? "2px solid" : "none",
          borderColor: `${color}.main`,
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Circle type={type} isContained color={color}>{label}</Circle>
        <Typography
          variant="subtitle2"
          color={color}
          sx={{
            position: "absolute",
            bottom: "110%",
            width: "300%",
            left: "-100%",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "0.65rem",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Stack>
  );
}

function SchoolSlide() {
  return (
    <Box>
      <Typography variant="h2">
        より<span>広く・深い探究学習</span>を<Br/>実現しませんか？
      </Typography>
      <Stack spacing={4} my={4}>
        <Balloon>
          生徒の探究テーマによっては、<Br/>校内で対応しきれず、<br/>
          内容を変えてもらわざるを<Br/>得ない時がある
        </Balloon>
        <Balloon type="right">
          生徒が探究の中で<Br/>良いアイデアを考えても、<br/>
          それを実行するところまで<Br/>なかなか経験させられない
        </Balloon>
      </Stack>
      <Typography variant="h3">
        探究応援プラットフォームにより、<Br/><span>探究学習の多様化と深化</span>を実現
      </Typography>
      <Typography variant="subtitle2">
        ※学校機関へは無償提供
      </Typography>
      <CtaBox type="school" />
    </Box>
  );
}

function CompanySlide() {
  return (
    <Box>
      <Typography variant="h2">
        <span>探究応援ネットワーク</span>に<Br/>登録しませんか？
      </Typography>
      <Typography variant="body2" textAlign="center">
        ワカモノの本気の創造を支援するからこそ、<br/>
        単なる教育支援にとどまらず、<Br/>次世代イノベーションの種に。
      </Typography>
      <Grid container spacing={1} mt={4} justifyContent="center">
        <Grid item xs={6} sm={4} p={2}>
          <Circle type="csr">本質的な<br/><span>教育CSR</span>活動</Circle>
        </Grid>
        <Grid item xs={6} sm={4} p={2}>
          <Circle type="marketing">次世代人材の<br/><span>企業認知</span>の拡大</Circle>
        </Grid>
        <Grid item xs={6} sm={4} p={2}>
          <Circle type="idea">ワカモノとの共創による<br/><span>アイデア創発</span></Circle>
        </Grid>
      </Grid>
      <Typography variant="h3">
        ネットワークに登録することで、<Br/><span>学校との接点構築や企画制作</span>をサポート
      </Typography>
      <CtaBox type="company" />
    </Box>
  );
}

function CtaBox(props) {
  const { type } = props;

  return (
    <Box mt={4}>
      <Typography variant="subtitle1">
        まずはお気軽にご相談ください
      </Typography>
      <CtaBtn
        href={googleFormUrl[type]}
        color="success"
      >
        問い合わせる
      </CtaBtn>
    </Box>
  );
}
