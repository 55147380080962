import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import NavItem from "../elements/NavItem";
import CtaBtn from "../elements/CtaBtn";
import ContactMenu from "../elements/ContactMenu";

import logo from "../assets/logo.png";

export default () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [currentSectionId, setCurrentSectionId] = useState();
  const [selectedSectionId, setSelectedSectionId] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isTop = (location.pathname === "/");

  useEffect(() => {
    if (!isTop) { return; }
    function handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSectionId(entry.target.id);
          entry.target.classList.add("appeared");
        }
      });
    }
    const observerOptions = {
      root: null,
      rootMargin: "-33% 0px -25% 0px",
    };
    const observer = new IntersectionObserver(handleIntersection, observerOptions);
    const sections = document.getElementsByTagName("section");
    for (const section of sections) {
      observer.observe(section);
    }
  }, []);

  function handleNavClick(id) {
    if (isDrawerOpen) { setIsDrawerOpen(false); }
    setSelectedSectionId(id);
    setCurrentSectionId(id);
    setTimeout(() => {
      setSelectedSectionId(undefined);
    }, 500);
  }

  function handleDrawerToggle() {
    setIsDrawerOpen((prev) => !prev);
  }

  return (
    <header style={{ position: "sticky", top: 0, right: 0, left: 0, backgroundColor: "rgba(255,255,255,0.9)", zIndex: 10 }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {isTop && (
            <Stack
              sx={{
                flex: 1,
                display: { xs: "flex", md: "none" },
                alignItems: "flex-start",
              }}
            >
              <IconButton
                color="inherit"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </Stack>
          )}

          <Stack
            sx={{
              flex: { xs: 1, md: 0 },
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <a href="/">
              <img src={logo} alt="e-donuts" width="160px" />
            </a>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            sx={{
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            {isTop && navList.map(({ id, label }) => (
              <NavItem
                key={id}
                id={id}
                label={label}
                onClick={handleNavClick}
                focusedSectionId={selectedSectionId || currentSectionId}
                sx={{ display: { xs: "none", md: "flex" } }}
              />
            ))}

            <CtaBtn
              color="secondary"
              width={140}
              size="medium"
              EndIcon={KeyboardArrowDownIcon}
              className="menu-btn"
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              Contact
              <ContactMenu />
            </CtaBtn>
          </Stack>
        </Toolbar>
      </Container>

      {isTop && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "66%", maxWidth: "360px" },
          }}
        >
          <Stack spacing={4} p={2}>
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "center",
                height: "64px",
              }}
            >
              <img src={logo} alt="e-donuts" width="160px" />
            </Stack>

            {navList.map(({ id, label }) => (
              <NavItem
                key={id}
                id={id}
                label={label}
                onClick={handleNavClick}
                focusedSectionId={selectedSectionId || currentSectionId}
              />
            ))}
          </Stack>
        </Drawer>
      )}

      {/* <Stack
        direction="row"
        spacing={1.5}
        alignItems="center"
        justifyContent="center"
        component="a"
        href="https://camp-fire.jp/projects/view/658658"
        target="_blank"
        height={32}
        width="100%"
        bgcolor="rgba(72,176,203,0.9)"
        sx={{ textDecoration: "none" }}
      >
        {!isXs && (
          <img
            src="https://static.camp-fire.jp/assets/goodmorning/logo_white-112b15b5ac3c7a85b60abffd4f29392dca72145f584498f3c3711ab893752604.png"
            alt="CAMPFIRE"
            height="20px"
          />
        )}
        <Typography fontSize={15} lineHeight={1} letterSpacing="0.18rem" textAlign="center" color="white.main">
          {!isXs && "にて"}
          <strong>クラウドファンディング実施中！</strong>
        </Typography>
        <ChevronRightIcon color="white" />
      </Stack> */}
    </header>
  );
}

const navList = [
  { id: "introduction", label: "Who We Are" },
  { id: "service", label: "Service" },
  { id: "news", label: "News" },
  { id: "report", label: "Report" },
  { id: "team", label: "Team" },
  { id: "about", label: "About" },
];
