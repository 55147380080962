import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

import reportNew from "../assets/report-new.png";
import reportQuotation from "../assets/report-quotation.png";

export default (props) => {
  const { title, date, url, imgUrl } = props;

  return (
    <Card elevation={4} sx={{ overflow: "visible", minHeight: "270px" }}>
      <CardActionArea
        component="a"
        href={url}
        target="_blank"
      >
        <CardMedia
          component="img"
          image={imgUrl}
          alt={title}
        />
        <CardContent sx={{ position: "relative" }}>
          <Typography gutterBottom variant="h3" m={0} textAlign="left">
            {title}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "0.6rem", color: "#666" }}>
            {date}
          </Typography>
          <img
            src={reportQuotation}
            alt=""
            width="18px"
            style={{ position: "absolute", top: "-10px", left: "16px" }}
          />
        </CardContent>
        {(new Date() - new Date(date) < 14 * 24 * 60 * 60 * 1000) && (
          <img
            src={reportNew}
            alt="NEW"
            width="20%"
            style={{ position: "absolute", top: "-20px", left: "16px" }}
          />
        )}
      </CardActionArea>
    </Card>
  );
}
