import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import logoWhite from "../assets/logo-white.png";

export default () => {
  return (
    <Box sx={{ bgcolor: "#050505" }} py={8}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Link href="/policy" variant="h6" color="white.main">
          プライバシーポリシー
        </Link>
      </Box>
      <Grid container columnSpacing={1} rowSpacing={4} maxWidth="md" justifyContent="center" alignSelf="center" m="auto">
        <Grid item xs={12} md={6} m="auto">
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem sx={{ bgcolor: "white.main" }} />}
            spacing={2}
            alignSelf="center"
            alignItems="center"
            justifyContent="center"
          >
            <Stack>
              <img src={logoWhite} alt="e-donuts" width="160px" />
              <Typography variant="h6" mt={0.5} fontWeight="bold">
                一般社団法人 e-donuts
              </Typography>
            </Stack>
            <Typography variant="h6">
              10代の「やってみたい」を<br />育てる、叶える。
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} m="auto">
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Typography variant="h6">
              〒610-1121 京都市西京区大原町上里北ノ町907-9
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "0.5rem", opacity: 0.7 }}>
              Copyright @ e-donuts All Rights Reserved.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}