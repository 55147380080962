import { createTheme } from "@mui/material";

const palette = {
  primary: {
    main: "#368ed2",
  },
  secondary: {
    main: "#000000",
  },
  success: {
    main: "#fbbd08",
  },
  darkGrey: {
    main: "#999999",
  },
  grey: {
    main: "#eeeeee",
  },
  white: {
    main: "#ffffff",
  },
};

export default createTheme({
  palette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "bold",
          lineHeight: "inherit",
          "& .MuiButton-endIcon": {
            position: "absolute",
            right: 16,
          },
        },
        sizeLarge: {
          padding: "0.8rem",
          width: "40%",
        },
        sizeMedium: {
          padding: "0.5rem",
          width: "140px",
        },
        sizeSmall: {
          padding: "0.4rem",
          "& .MuiButton-endIcon": {
            position: "relative",
            right: "inherit",
          }
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          overflow: "hidden",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "24px",
          fontSize: "0.8rem",
          color: "#333",
          "&.th": {
            fontSize: "0.7rem",
            color: "#666",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: "'Roboto', sans-serif",
          fontWeight: "bold",
          fontSize: "1.7rem",
          lineHeight: "2.8rem",
          letterSpacing: "0.2rem",
          color: "#444",
          marginBottom: "32px",
        },
        h2: {
          fontWeight: "bold",
          fontSize: "1.15rem",
          lineHeight: "2rem",
          letterSpacing: "0.1rem",
          margin: "32px 0",
          "&>span": {
            color: palette.primary.main,
            textDecoration: "underline",
            textDecorationStyle: "dotted",
            textUnderlineOffset: 6,
            textDecorationThickness: 1.5,
          },
        },
        h3: {
          fontWeight: "bold",
          fontSize: "0.9rem",
          lineHeight: "1.6rem",
          letterSpacing: "0.05rem",
          marginTop: "32px",
          "&>span": {
            color: palette.primary.main,
          },
        },
        h4: { //name
          textAlign: "left",
          fontWeight: "bold",
          fontSize: "1.2rem",
          letterSpacing: "0.05rem",
          marginTop: "0 !important",
        },
        h5: { //role
          textAlign: "left",
          fontWeight: "bold",
          fontSize: "0.6rem",
          letterSpacing: "0.04rem",
          color: palette.primary.main,
          paddingLeft: "0.4rem",
          borderLeft: "1px solid",
          borderLeftColor: palette.primary.main,
        },
        h6: { //footer
          color: "#fff",
          fontSize: "0.7rem",
          lineHeight: "1.2rem",
          letterSpacing: "0.05rem",
        },
        caption: {
          fontSize: "0.7rem",
          lineHeight: "1.2rem",
          letterSpacing: "0.18rem",
          color: palette.primary.main,
        },
        body1: {
          fontSize: "0.8rem",
          lineHeight: 3,
          letterSpacing: "0.08rem",
          color: "#222",
        },
        body2: {
          textAlign: "left",
          fontSize: "0.8rem",
          lineHeight: "1.6rem",
          letterSpacing: "0.04rem",
          color: "#444",
        },
        subtitle1: {
          fontWeight: "bold",
          fontSize: "0.7rem",
          lineHeight: "1.8rem",
          letterSpacing: "0.12rem",
        },
        subtitle2: {
          fontSize: "0.7rem",
          lineHeight: "1.2rem",
          color: "#555",
        },
        button: {
          fontFamily: "'Roboto', sans-serif",
          textTransform: "none",
          fontWeight: "bold",
          lineHeight: "inherit",
        },
      },
    },
  },
  typography: {
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', sans-serif",
  },
  zIndex: {
    snackbar: 1000,
  }
});
