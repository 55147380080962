import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import csr from "../assets/company-csr.png";
import marketing from "../assets/company-marketing.png";
import idea from "../assets/company-idea.png";
import school from "../assets/school.png";
import company from "../assets/company.png";
import book from "../assets/manual-icon-book.png";
import classroom from "../assets/manual-icon-classroom.png";
import framework from "../assets/manual-icon-framework.png";
const images = { csr, marketing, idea, school, company, book, classroom, framework };

export default (props) => {
  const { children, type, isContained, color = "primary", sx, size = isContained ? "72px" : "100%", imgSize = isContained ? "24px" : "32px", flexDirection = "column" } = props;

  return (
    <Stack
      sx={{
        aspectRatio: 1,
        width: size,
        maxWidth: "180px",
        borderRadius: "50%",
        backgroundColor: isContained ? `${color}.main` : "#fff",
        border: isContained ? undefined : "2px solid",
        borderColor: `${color}.main`,
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
      direction={flexDirection}
    >
      <img src={images[type]} alt={type} style={{ maxWidth: imgSize, maxHeight: imgSize }} />
      <Typography
        variant="body2"
        sx={{
          fontWeight: "bold",
          fontSize: "0.7rem",
          lineHeight: "1.4rem",
          textAlign: "center",
          "&>span": {
            color: "primary.main",
          },
          color: isContained ? "#fff" : undefined,
          marginTop: isContained ? "8px" : "16px",
        }}
      >
        {children}
      </Typography>
    </Stack>
  );
}
