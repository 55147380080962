import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";

import Section from "../components/Section";

export default () => {
  const rowList = [
    { key: "企業名", value: "一般社団法人 e-donuts" },
    { key: "代表理事", value: "藤原 彪人" },
    { key: "設立", value: "2023年5月" },
    { key: "メールアドレス", value: "info@e-donuts.com" },
    { key: "所在地", value: "〒610-1121 京都市西京区大原町上里北ノ町907-9" },
  ];

  return (
    <Section
      isDark
      id="about"
      caption="会社概要"
      h1="About"
    >
      <Container maxWidth="md" sx={{ padding: { xs: 0, sm: "inherit" } }}>
        <TableContainer component={Paper} elevation={4}>
          <Table aria-label="simple table">
            <TableBody>
              {rowList.map(({ key, value }) => (
                <TableRow key={key}>
                  <TableCell as="th" className="th">{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Section>
  );
}
